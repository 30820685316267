import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
// import Icon from './Icons'
import { FaFacebookF, FaLinkedinIn, FaWhatsapp, FaEnvelope, FaAngleDown } from 'react-icons/fa';
// import { media, padding, width, zIndex } from "./vars"
import LazyLoad from 'react-lazyload';

const Root = styled.nav`
  ${({ invert, theme }) => `
    color: ${invert ? theme.background : theme.color};
  `};

  a {
    color: inherit;
    text-decoration: none;
  }
`

const prop = (getter, callback) => {
  let val
  try {
    val = getter()
  } catch (e) { }
  return callback(val)
}

const SiteNav = ({
  brand = {},
  contact = {},
  menu,
  onCloseMenu,
  invert,
  theme,
  navbarOpen,
  megamenuOpen,
  activeClass,
  navbarCss,
  megamenuCss
}) => (
    <Root theme={theme} invert={invert} key="123534535">
      <nav className={`navbar navbar-expand-lg navbar-light bg-light norden-navbar px-3 ${activeClass}`}>
        <div className="container">
          <div className="norden-logo order-lg-1">
            <Link to="/" onClick={onCloseMenu}>
              {prop(
                () => brand.logo.asset.url,
                url =>
                  url ? (
                    <LazyLoad>
                      <img style={{ width: "9em" }} alt={brand.name || 'No name'} src={brand.logo.asset.url} className="d-inline-block align-top mr-3" />
                    </LazyLoad>
                  ) : (
                      brand.name || 'No name'
                    )
              )}
            </Link>
          </div>
          <span className="navbar-socials order-lg-3">
            <a href={contact.linkedin ? contact.linkedin : '#'} target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
            <a href={contact.facebook ? contact.facebook : '#'} target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href={contact.whatsapp ? contact.whatsapp : '#'} target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
            <a href={contact.email ? 'mailto:' + contact.email : '#'} rel="noopener noreferrer">
              <FaEnvelope />
            </a>
          </span>
          <button className="navbar-toggler order-lg-4" type="button" onClick={navbarOpen} >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`order-lg-2 ${navbarCss}`} id="navbarNav">
            <ul className="navbar-nav">
              {(menu.items || []).map((item, i) => (
                <>
                  {(item.key === "650e3508826e") ?
                    <li key={item.key} onClick={megamenuOpen} className="nav-item dropdown-nord megamenu-li">
                      <div className="nav-link">{item.title} <FaAngleDown /></div>
                      <div className={`dropdown-menu megamenu ${megamenuCss}`}>
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="row px-3">
                                <Link to={item.to} className="mb-3 paragraph">
                                  <h1 className="h5 font-weight-bold">{item.title}</h1>
                                  <p>En moderne arkitektur -Blokchain, AI, Magento webshop, JAMStack, ERP integrationer og Mobil App Udvikling.</p>
                                </Link>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-sm-6 col-lg-6">
                                  <Link to="/magento-webshop/" className="mr-1 mb-2 d-block paragraph">
                                    <h1 className="h5 font-weight-bold">Magento Webshop</h1>
                                    <p> Vi designer og bygger Magento webshops. Har i brug for en ny eller forbedret webshop? </p>
                                  </Link>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                  <Link to="/jamstack/" className="mb-2 d-block paragraph">
                                    <h1 className="h5 font-weight-bold">JAMStack</h1>
                                    <p> JAMStack design og udvikling. En JAMStack er ”Formel 1” inden for SEO og ydeevne! </p>
                                  </Link>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                  <Link to="/blokchain/" className="mr-1 mb-2 d-block paragraph">
                                    <h1 className="h5 font-weight-bold">Blokchain</h1>
                                    <p> En blockchain (blok-kæde) er en kontinuerligt voksende liste af blokke, som er forbundet med kryptografi. </p>
                                  </Link>
                                </div>
                                <div className="col-sm-6 col-lg-6">
                                  <Link to="/deep-learning-ai/" className="mb-2 d-block paragraph">
                                    <h1 className="h5 font-weight-bold">Deep Learning AI</h1>
                                    <p> Kunstig intelligens er den gren inden for datalogien, der beskæftiger sig med at få maskiner til at "tænke" </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="col-12 mt-5">
                                <div className="h5 pl-4 font-weight-bold">Hurtige links</div>
                                <Link to="/magento-udvikler-magento-danmark/" className="dropdown-item"><h1 className="h5">Magento</h1></Link>
                                <Link to="/magento-b2b-og-b2c-webshop/" className="dropdown-item"><h1 className="h5">Magento B2B og B2C</h1></Link>
                                <Link to="/app-udvikler/" className="dropdown-item"><h1 className="h5">Apps</h1></Link>
                                <Link to="/erp-integration/" className="dropdown-item">ERP Integration</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    :
                    <li key={item.key} className="nav-item">
                      <Link to={item.to} onClick={onCloseMenu} className="nav-link">
                        {item.title}
                      </Link>
                    </li>
                  }
                </>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </Root>
  )

export default SiteNav
